import { navigate } from "gatsby";
import React from "react";
import { useSelector } from "react-redux";

import { getLang } from "../store/slices/lang";

function Index() {
  const lang = useSelector(getLang);
  React.useEffect(() => {
    navigate(`/${lang}/`);
  }, [lang]);

  return null;
}

/**
 * Корневая страница
 * @module src/pages/index
 * @param {Object} props - объект свойств компонента React
 * @param {String} props.children - дочерние элементы
 */
export default Index;
